import React from 'react';

interface HeaderLogoProps {
  logo: React.ReactNode;  
}

const HeaderLogo: React.FC<HeaderLogoProps> = ({ logo}) => {
  return (
    <div className="header-logo">
      {logo}
      <div className="app-name"></div>
    </div>
  );
};

export default HeaderLogo;