import React, { useState } from 'react';
import "./faq.css"
interface FAQItem {
  question: string;
  answer: string;
}

const faqData: FAQItem[] = [
  {
    question: 'What is your return policy?',
    answer: 'We offer a subscription service and do not offer refunds.  If you wish to cancel your subscription within 30 days, we will charge an admin charge of $5 and refund your subscription difference to the card that was used to make the initial payment.  You can use the software as long as you want without a subscription. See benefits of subscription below.',
  },
  {
    question: 'What are the benefits of a subscription?',
    answer: 'Subscribers get multiple exercises per day, rather than a single exercise reminder.  Subscribers receive our wellness tip into the application, non-subscribers will have to visit our web site to view wellness tips. Subscribers receive prioritised support.  Subscribers have the potential to receive offers from sponsors (this will depend upon the sponsor offers)',
  },
  {
    question: 'Do you offer technical support?',
    answer: 'Yes, we offer technical support, prioritised upon your subscription, based upon on an 8 hour response.  Contact wellness.support@candcsolutions.co.uk',
  },
  {
    question: 'Where are you located?',
    answer: 'We are located in the United Kingdom',
  },
  {
    question: 'How do I download your software ?',
    answer: `You can follow the link in downloads to download our software.
    Note : In your browser if you obtain a warning that file is suspicious, the reason from google is
    Suspicious:
      An uncommon or unfamiliar file
      A file that may try to evade detection, such as by hiding malware or viruses inside archive files like .zip or .rar. A password may also be added to avoid detection.
    You will need to continue, and download the suspicious file.  Please then run this through anti-virus, but the file is seen as uncommon.  Until we have sufficient downloads, then this will warning will continue.
    Once the file is downloaded and virun scanned you should be good to run the installer.
    We have different installers to try and cater for your your scenario.
    1.  MSI Installer - this is a trusted installer which will advise that the application was built by us.  If you are unsure and want to see no warnings use this installer, but it is more complex as you need to download and then open the installer
    2.  ClickOnce Installer - this is an installer. This installer is easier to use, but may have warnings from your browser.  This is the most common installer to use, and will auto update the application as we push new changes.
    `
  },
];

export const FAQ: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const toggleFAQ = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="faq-section">
      <div className='faq-container'>
      {faqData.map((item, index) => (
        <div key={index} className="faq-item">
          <div className="faq-question" onClick={() => toggleFAQ(index)}>
            <h2>{item.question}</h2>
            <span className="chevron">{activeIndex === index ? '▲' : '▼'}</span>
          </div>
          {activeIndex === index && <p className="faq-answer">{item.answer}</p>}
        </div>
      ))}
      </div>
    </div>
  );
};

export default FAQ;