import "./documentation.css";

import React, { useState, useEffect } from 'react';

const sections = [
  { index: 'installation', description: 'Installation' },
  { index: 'registration', description: 'Registration' },
  { index: 'subscription', description: 'Subscription' },
  { index: 'troubleshooting', description: 'Troubleshooting' },
  // Add more sections as needed
];

export const DocumentationSection = () => {
  const [selectedSection, setSelectedSection] = useState('');
  const [html, setHtml] = useState('');

  useEffect(() => {
    if (selectedSection) {
      fetch(`/help/${selectedSection}.html`)
        .then(response => response.text())
        .then(text => setHtml(text));
    }
  }, [selectedSection]);

  return (
    <div className='container'>
      <div className='index'>
        <h2 className='index-title'>Help Index</h2>
        <ul className='list'>
          {sections.map(section => (
            <li key={section.index} className='list-item'>
              <button onClick={() => setSelectedSection(section.index)} className='button'>
                {section.description}
              </button>
            </li>
          ))}
        </ul>
      </div>
      <div className='content'>
        {html ? (
          <div dangerouslySetInnerHTML={{ __html: html }} />
        ) : (
          <p>Select a section from the index to view its content.</p>
        )}
      </div>
    </div>
  );
};

export default DocumentationSection;