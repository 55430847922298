import {useEffect, useState} from 'react';
import './company.css';


export function TermsOfReferenceSection() {
  const [termsOfServiceHtml, setTermsOfServiceHtml] = useState('');

  useEffect(() => {
    fetch('../terms/termsofservice.html') // Adjust the path as necessary
      .then(response => response.text())
      .then(html => setTermsOfServiceHtml(html));
  }, []);

  return (
    <div className="company-section">
      <h2>Terms of Reference Section</h2>
      <div dangerouslySetInnerHTML={{ __html: termsOfServiceHtml }} />
    </div>
  );

}

export default TermsOfReferenceSection;