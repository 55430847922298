import React from 'react';
import './WellnessTipsPanel.css';

interface WellnessTip {
  title: string;
  description: string;
}

const WellnessTipsPanel: React.FC<WellnessTip> = ({ title, description }) => {
  const googleSearchLink = `https://www.google.com/search?q=${encodeURIComponent(title)} "wellness"`;

  return (
    <div className="wellness-tips-panel">
      <h3>{title}</h3>
      <p>{description}</p>
      <a href={googleSearchLink} target="_blank" rel="noopener noreferrer">
        Search on Google
      </a>
    </div>
  );
};

export default WellnessTipsPanel;