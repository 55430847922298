import React from 'react';
import "./product.css";

export function Articles() {
  return (
    <div className="product-section">
      <h2>Articles</h2>
      <h3>Workstation Health</h3>
      <span> Article 1 : 
      <a href="https://www.linkedin.com/pulse/welcome-workstation-wellness-healthy-nails-wellness-administrator-eyiye/">
      [LinkedIn Healthy Nails]
      </a> 
      <a href="https://medium.com/@wellness_4416/workstation-wellness-healthy-nails-bff0251fc25c"> [Medium Healthy Nails]</a>
      </span>
      <p></p>
    </div>
  );
}

export default Articles;