import React from 'react';
import "./product.css";

export function ServiceSection() {
  return (
    <div className="product-section">
      <h2>Products</h2>
      <h3>Workstation Health</h3>
      <p>C&C Solutions <a href="https://candcsolutions.blob.core.windows.net/downloads/WellnessInstaller.msi" download><b>Workstation Health</b></a> provides an application which can ensure that you follow good ergonomic practice for your Display Screen Equipment.</p>
      <span className="pricing">£12.00 </span><span>annual subscription - pay in app</span>
      <p>Review the user guide for more information</p>
      <h2>Influencer Programme</h2>      
      <p>C&C Solutions offer a influencer bonus.  Please ensure you are registered before recommending our applications.</p>
      <p>Contact <a href="mailto:influencer@candcsolutions.co.uk">influencer@candcsolutions.co.uk</a> for details of our influencer programme.</p>
      <p>We offer a range of options from discounts & bonuses including domain licensing.</p>
    </div>
  );
}

export default ServiceSection;