import {useEffect, useState} from 'react';
import './company.css';


export function PrivacyPolicySection() {
  const [privacyPolicyHtml, setPrivacyPolicyHtml] = useState('');

  useEffect(() => {
    fetch('../terms/privacypolicy.html') // Adjust the path as necessary
      .then(response => response.text())
      .then(html => setPrivacyPolicyHtml(html));
  }, []);

  return (
    <div className="company-section">
      <h2>Privacy Policy</h2>
      <div dangerouslySetInnerHTML={{ __html: privacyPolicyHtml }} />
    </div>
  );

}

export default PrivacyPolicySection;