import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import './App.css';
import { CompanySection } from './web-sections/company';
import { ServiceSection } from './web-sections/product';
import { TermsOfReferenceSection } from './web-sections/termsOfReference';
import { DocumentationSection } from './web-sections/documentation';
import { FAQ } from './web-sections/faq';
import HeaderLogo from './components/headerLogo';
import DownloadSection from './web-sections/downloads';
import PrivacyPolicySection from './web-sections/privacyPolicy';
import WellnessTipsPanel from './components/WellnessPanel';
import allTips from './tips/wellness.json';
import Articles from './web-sections/articles';



function App() {
  const [tipoftheday, setTipOfTheDay] = React.useState({title: "", description: ""})
  
  React.useEffect(() => {
    const tempTip : any = allTips[Math.floor(Math.random() * allTips.length)]
    setTipOfTheDay(tempTip)
  }, []);
  return (
    
      <div className="App">
        <WellnessTipsPanel title={tipoftheday.title} description={tipoftheday.description} />
        <header>
          <nav>
          <img src={'/images/CCLogo.png'} alt="C&amp;C" className="logo" />                      
            <ul>
              <li><Link to="/company">Company</Link></li>
              <li><Link to="/products">Products</Link></li>
              <li><Link to="/documentation">Documentation</Link></li>
              <li><Link to="/faq">FAQ's</Link></li>
              <li><Link to="/download">Downloads</Link></li>
              <li><Link to="/articles">Articles</Link></li>                            
            </ul>
            <HeaderLogo logo={<div className="header-icon">C&amp;C</div>}></HeaderLogo>
          </nav>
          
        </header>
        <main>
          <Routes>
            <Route path="/company" element={<CompanySection />} />
            <Route path="/products" Component={ServiceSection} />
            <Route path="/download" Component={DownloadSection} />
            <Route path="/documentation" Component={DocumentationSection} />
            <Route path="/termsofreference" Component={TermsOfReferenceSection} />
            <Route path="/privacypolicy" Component={PrivacyPolicySection} />
            <Route path="/faq" Component={FAQ}/>
            <Route path="/articles" Component={Articles}/>
            <Route path="/" Component={CompanySection} />
          </Routes>
        </main>
        <footer>          
          <nav>
            <ul>
              <li className="footer-text"><Link to="/termsofreference">Terms of Reference</Link></li>
              <li className="footer-text"><Link to="/privacypolicy">Privacy Policy</Link></li>
              {/* Add more footer links as needed */}
            </ul>
          </nav>
        </footer>
      </div>    
  );
}

export default App;