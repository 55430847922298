import React from 'react';
import "./company.css";

export function CompanySection() {
  return (
    <div className="company-section">
      <h2>Our Company</h2>
      <p>C&C Solutions is a company which delivers enterprise solutions to assist with productivity.</p>      
      <p>Check out our Services section for our list of services and features.</p>
      <p>Flexible licensing enables user or domain scope licensing</p>
    </div>
  );
}

export default CompanySection;