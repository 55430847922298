import React from 'react';
import "./product.css";

export function DownloadSection() {
  return (
    <div className="product-section">
      <h2>Downloads</h2>
      <h3>Workstation Health</h3>
      <a href="/clickonce/Wellness.application">
        Wellness ClickOnce Installer  - Recommended
      </a>
      <p></p>
      <a href="https://candcsolutions.blob.core.windows.net/downloads/WellnessInstaller.msi">
        Download Wellness Installer
      </a>
      <p></p>            
      <a href="/downloads/WellnessInstaller.msi" download>
        Download Wellness Installer (Slower)
      </a>
      <p></p>
    </div>
  );
}

export default DownloadSection;